import React, { useState } from 'react'
import Loader from "react-loader-spinner";
import axios from 'axios'
import { Link } from "gatsby"

import useDataLayer from '../../hooks/useDataLayer';
import view from "../modal/content/form.module.scss"
import grid from "../../scss/flexboxgrid.module.scss"
import "../contact/ContactForm.scss"

function FinanceWidget(props) {
  const { leadURL, extraData } = props
  const {VehicleInfo, Pricing} = extraData
  const financeSettings = props.data;

  const localStorage = typeof window !== 'undefined' && window.localStorage
  const pushToDataLayer = useDataLayer()
  
  function formatPrice(price, defaultPrice) {
    let thePrice = (defaultPrice != undefined && price == 0
      ? defaultPrice
      : '$' + price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','))
    return thePrice
  }

  let vehicle = {}
if(VehicleInfo != null && Pricing != null) {
  vehicle = {
    VIN: VehicleInfo.VIN != null ? VehicleInfo.VIN : '',
    StockNumber: VehicleInfo.StockNumber != null ? VehicleInfo.StockNumber : '',
    Year: VehicleInfo.Year != null ? VehicleInfo.Year : '',
    Make: VehicleInfo.Make != null ? VehicleInfo.Make : '',
    Model: VehicleInfo.Model != null ? VehicleInfo.Model : '',
    Trim: VehicleInfo.Trim != null ? VehicleInfo.Trim : '',
    List: Pricing.List != null ? Pricing.List : '',
    ExtraPrice1: Pricing.ExtraPrice1 != null ? Pricing.ExtraPrice1 : ''
  }
}

  const [fields, setFields] = useState({
    firstName: null, lastName: null, email: null, phoneNumber: null, comments: null, 
    downpayment: null, purchaseType: null, 
    siteId: props.site_id, pageSource: props.pageSource, pageURL: props.pageURL
  })
  const [error, setError] = useState({
    firstName: false, lastName: false, email: false, phoneNumber: false, comments: false
  })
  const [statusMsg, setStatusMsg] = useState("")
  const [loading, isLoading] = useState(false)

  const handleTextInput = (name, value) => {
    setStatusMsg("")
    setFields({ ...fields, ...{ [name]: value } })
  }

  const phoneMask = (e) => {
    var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
  }

  const sendRequest = () => {
    const request = { ...fields, vehicle }

    axios.post(leadURL, JSON.stringify(request)) // this needs an actual backend link
      .then((res) => {
        pushToDataLayer("form-submission", res)
        pushToDataLayer("finance", res)
        setStatusMsg("Success! The message has been sent!")
        isLoading(false)
      })
      .catch(err => {
        console.log(err)
        setStatusMsg("Uh oh! Something went wrong, please try again!")
        isLoading(false)
      })
      
  }

  const submitForm = async (e) => {
    e.preventDefault()
    isLoading(true)

    const empties = ['firstName', 'lastName', 'email', 'phoneNumber'].map(value => {
      const isEmpty = fields[value] !== null && fields[value].length > 0
      setError({ ...error, ...{ [value]: true } })
      return isEmpty
    })

    if (empties.indexOf(false) > -1) {
      setStatusMsg("One or more fields are missing!")
      isLoading(false)
    } else {
      sendRequest()
    }

  }

  const [isOpen, toggleOpen] = useState([false, false, false,false,false,false,false])

  const toggleMenu = (idx) => {
    const _isOpen = isOpen.map((val, jdx) => {
      return idx == jdx ? !val : false
    })
    if(idx === 3){
      document.querySelector('.more-filters').classList.toggle('more-filters_visible')
    }
    toggleOpen(_isOpen)
  }

  const hideMenus = () => {
    const _isOpen = isOpen.map(() => (false))
    toggleOpen(_isOpen)
    //document.querySelector('.more-filters').classList.remove('more-filters_visible')
  }

  function getStorageItem(item) {
    return (typeof localStorage.getItem !== 'undefined' ? localStorage.getItem(item) : "[]")
  }

  function setStorageItem(item, value) {
    typeof localStorage.setItem !== 'undefined'
      ? localStorage.setItem(item, value)
      : console.log('no localStorage')
  }

  return (
    <div className={"contact-root"}>
       <div className="contact-header">
          <h1 className="contact-title">{financeSettings.title}</h1>
          <p className="contact-instructions">{financeSettings.text}</p>
          <Link className="contact-link" to={financeSettings.link}>{financeSettings.linkText}</Link>
      </div>
      <div className={`contact-formCont`}>

      <div className={"contact-first-line"}>
        <div className={`contact-inputCont ${grid["col-md-6"]} ${grid["col-sm-12"]}`} >
        <label htmlFor="downpayment">Down Payment</label>
        <input className="contact-input" id="downpayment" name="downpayment" type="number" min="0" step="any" placeholder=""
            onChange={(e) => handleTextInput(e.target.name, e.target.value)} />
        </div>

        <div className={`contact-inputCont ${grid["col-md-6"]} ${grid["col-sm-12"]}`} >
      <label htmlFor="purchaseType">Purchase Type</label>
      <ul className="dropdown-sorting"  onMouseLeave={hideMenus}  onClick={() => toggleMenu(6)} style={{padding: "0 0 10px 0", height: "40px"}}>
          <li className="dropdown-v1 srp-dropdown__model" onMouseLeave={hideMenus}  
              onClick={() => {toggleMenu(6); handleTextInput('purchaseType', getStorageItem("finance:Type"))}} style={{height: "40px"}}>
            <div className="sr-dropdown__inner">
              <p className="sr-dropdown__name" style={{marginTop: "3px"}}>{getStorageItem("finance:Type") ?? "Lease"}</p>
              <input hidden id="purchaseType" name="purchaseType" value={getStorageItem("finance:Type")}
                onChange={(e) => handleTextInput(e.target.name, e.target.value)}/>
            </div>
            {isOpen[6] &&
            (<ul className="sub-dropdown-v1 search-dropdown" style={{top:"40px"}}>
              <li className="sub-dropdown-v1__item"  onClick={() => { toggleMenu(6); setStorageItem("finance:Type", "Lease"); }}>Lease</li>
              <li className="sub-dropdown-v1__item"  onClick={() => { toggleMenu(6); setStorageItem("finance:Type", "Finance"); }}>Finance</li>
              <li className="sub-dropdown-v1__item"  onClick={() => { toggleMenu(6); setStorageItem("finance:Type", "Cash"); }}>Cash</li>
            </ul>)}
          </li>
      </ul>
      </div>
      </div>

      <div className={`contact-inputCont contact-first-name ${grid["col-md-6"]} ${grid["col-sm-12"]}`} >
        <label htmlFor="firstName">First Name *</label>
        <input className="contact-input" id="firstName" name="firstName" placeholder=""
          onChange={(e) => handleTextInput(e.target.name, e.target.value)} />
      </div>

      <div className={`contact-inputCont ${grid["col-md-6"]} ${grid["col-sm-12"]}`}>
        <label htmlFor="lastName">Last Name *</label>
        <input className="contact-input" id="lastName" name="lastName" placeholder=""
          onChange={(e) => handleTextInput(e.target.name, e.target.value)} />
      </div>

      <div className={`contact-inputCont contact-phone ${grid["col-md-6"]} ${grid["col-sm-12"]}`}>
        <label htmlFor="phoneNumber">Phone *</label>
        <input className="contact-input" type="tel" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
          id="phoneNumber" name="phoneNumber" placeholder="(555) 555-5555"
          onChange={(e) => handleTextInput(e.target.name, e.target.value)}
          onInput={phoneMask}
        />
      </div>

      <div className={`contact-inputCont ${grid["col-md-6"]} ${grid["col-sm-12"]}`}>
        <label htmlFor="email">Email *</label>
        <input className="contact-input" type="email" id="email" name="email" placeholder=""
          onChange={(e) => handleTextInput(e.target.name, e.target.value)} />
      </div>

      <div className="contact-inputCont contact-comment" style={{marginRight: "0"}}>
        <label htmlFor="comments">Comment</label>
        <textarea className="contact-input" id="comments" name="comments" placeholder=""
          onChange={(e) => handleTextInput(e.target.name, e.target.value)} />
      </div>

      <div className="contact-inputCont contact-border">
      <p className="contact-disclaimer">{financeSettings.disclaimer}</p>
        <div className={`contact-response ${statusMsg.length && "contact-failure"}`}>
          {statusMsg}
        </div>
        <button className="contact-button" onClick={submitForm}>
          {loading === false ? financeSettings.buttonText :
            <Loader type="ThreeDots" color="white" height={12} width={12} />
          }
        </button>
      </div>

    </div>
    </div>
  )
}

FinanceWidget.defaultProps = {
  leadURL: "",
  disclaimer: ""
}

export default FinanceWidget